@font-face {
  font-family: "HelveticaNeueLTStd";
  src: url("./assets/fonts/HelveticaNeueLTStd-BdCn.otf") format("opentype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(/public/images/landingBg.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #e1e2da;
  color: #fff;
  overflow-x: hidden;
  min-height: 100dvh;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body:has(.game-page) {
  background-image: url(/public/images/mobileBg.png);
}

body:has(.waiting-page) {
  background-image: url(/public/images/waitingScreen.png);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "HelveticaNeueLTStd", sans-serif;
  margin: 0;
  font-weight: 800;
}

p,
span {
  font-family: "celias";
  margin: 0;
}

img,
video {
  transition: all 500ms;
}

.App {
  position: relative;
  width: 100%;
}

.landing-vid {
  position: relative;
}

.hide {
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.show {
  opacity: 1;
  visibility: visible;
  z-index: 3;
}

.waiting-page {
  min-height: 100dvh;
  min-width: 100vw;
  background-image: url(/public/images/waitingScreen.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #010f3b;
}

.ascCode-img {
  position: fixed;
  right: 16px;
  bottom: 16px;
  width: 400px;
  object-fit: contain;
  height: 25.75px;
  z-index: 4;
  filter: invert(1);
}

body:has(.revert-code) .ascCode-img {
  filter: unset;
}

body:has(.revert-code) .footer-text {
  color: #fff;
}

@media screen and (max-width: 991px) {
  body {
    background-size: 100% 100%;
  }

  body:has(.game-page) .timer-wrapper {
    width: 80px;
    height: 80px;
  }

  body:has(.game-page) .timer-wrapper h2 {
    font-size: 38px;
    line-height: 34px;
  }

  body:has(.game-page) .timer-wrapper h4 {
    font-size: 20.15px;
    line-height: 18px;
  }

  body:has(.game-page) .footer-text {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
  }

  .ascCode-img {
    width: 187px;
    height: 12px;
    filter: unset;
  }
}
