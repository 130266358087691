.layout-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 50%;
  gap: 20px;
  width: 1080px;
  height: 1920px;
  transform: translate(-50%, 0%) scale(1);
  overflow: hidden;
}

.header {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  padding: 50px 60px 0 60px;
  z-index: 9;
}

.header > img {
  height: 160px;
}

.logo img {
  -o-object-fit: contain;
  object-fit: contain;
}

.content {
  flex-grow: 1;
  display: flex;
  width: 100%;
  position: relative;
}

.footer-text {
  font-family: Arial, Helvetica, sans-serif;
  position: fixed;
  left: 16px;
  bottom: 16px;
  font-size: 29px;
  color: #000;
  z-index: 4;
}

@media screen and (max-width: 991px) {
  body:has(.game-page) .header {
    width: 80px;
    padding: 10px 15px 0 15px;
  }

  body:has(.game-page) .header > img {
    width: 100%;
    height: 100%;
  }
}
