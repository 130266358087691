.game-page {
  position: relative;
  min-height: 100dvh;
}

.swipe-instruction {
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  width: 320px;
  -o-object-fit: contain;
  object-fit: contain;
  height: 80px;
}

.cta-btn {
  position: absolute;
  bottom: 98px;
  background: transparent;
  outline: 0;
  border: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.cta-btn img {
  width: 124px;
  height: 40px;
  -o-object-fit: contain;
  object-fit: contain;
}

.bottom-text {
  position: absolute;
  bottom: 110px;
  left: 50%;
  width: 240px;
  height: 30px;
  -o-object-fit: contain;
  object-fit: contain;
  transform: translateX(-50%);
}

.animate-bounce {
  -webkit-animation: bounce 1s infinite;
  animation: bounce 1s infinite;
}

@-webkit-keyframes bounce {
  0%,
  100% {
    transform: translate(-50%, -25%);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translate(-50%, 0);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translate(-50%, -25%);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translate(-50%, 0);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.dragon-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
  background: rgb(6 22 135 / 50%);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  z-index: 10;
}

.dragon-wrapper img {
  width: 400px;
  height: 194px;
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 70%);
}

.animate-dragon {
  -webkit-animation: dragonFly 2500ms ease forwards;
  animation: dragonFly 2500ms ease forwards;
}

@-webkit-keyframes dragonFly {
  0% {
    transform: translate(-50%, 70%);
  }
  100% {
    transform: translate(-50%, -100vh);
  }
}

@keyframes dragonFly {
  0% {
    transform: translate(-50%, 70%);
  }
  100% {
    transform: translate(-50%, -100vh);
  }
}
