.qr-main {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 210px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 200ms;
}

.qr-wrapper {
  padding: 10px;
  background: #fff;
  border: 16px solid #b4b9d3;
}

.home-domex-video {
  width: 1080px;
  height: 1920px;
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.timer-wrapper {
  width: 185px;
  height: 185px;
  background-image: url(../../../public/images/timerBg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  background-color: #fff;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 32px;
  left: 32px;
  z-index: 9;
}

.timer-wrapper h2 {
  font-size: 91px;
  line-height: 86px;
  color: #00168d;
}

.timer-wrapper h4 {
  font-size: 34.15px;
  line-height: 30px;
  color: #ff041f;
}
